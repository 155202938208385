import React from "react";
import { FaArrowRight } from "react-icons/fa6";
import { FaCheckCircle } from "react-icons/fa";
import Footer from "../Components/Footer/Footer";
import Navbar from "../Components/Navbar/Navbar";
import partner2 from "../assets/images/recruting/partner2.jpg";
import cta_4_shape2 from "../assets/img/normal/cta_4_shape2.png";
import { FaCircleCheck } from "react-icons/fa6";
import { FaWhatsapp } from "react-icons/fa";
import { Helmet } from "react-helmet";
import FixedEnquiry from "../Components/fixed compoents/FixedEnquiry";
import FixedWhatsapp from "../Components/fixed compoents/FixedWhatsapp";
import { Link } from "react-router-dom";
import TypesofEnquiry from "../Components/fixed compoents/TypesofEnquiry";
export const Recuriting = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <title>
          International Student Recruitment | Become an Education Partner With
          Us
        </title>
        <meta name="author" content="https://afynd.com/" />
        <meta
          name="description"
          content="Become an EduFynd Partner -Get Higher Commissions, Incentives, Tech Platform, Dedicated support and help you to grow with us. We are looking to build a network from the INDIA & GLOBAL market."
        />
        <meta name="keywords" content="" />
        <meta name="robots" content="INDEX,FOLLOW" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,shrink-to-fit=no"
        />

        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/assets/images/edufynd-fav-logo.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/assets/images/edufynd-fav-logo.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/assets/images/edufynd-fav-logo.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/assets/images/edufynd-fav-logo.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/assets/images/edufynd-fav-logo.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/assets/images/edufynd-fav-logo.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/assets/images/edufynd-fav-logo.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/assets/images/edufynd-fav-logo.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/assets/images/edufynd-fav-logo.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/assets/images/edufynd-fav-logo.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/assets/images/edufynd-fav-logo.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="/assets/images/edufynd-fav-logo.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/assets/images/edufynd-fav-logo.png"
        />
        <link rel="manifest" href="/assets/img/favicons/manifest.html" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta
          name="msapplication-TileImage"
          content="/assets/img/favicons/ms-icon-144x144.png"
        />
        <meta name="theme-color" content="#ffffff" />

        <link rel="preconnect" href="https://fonts.googleapis.com/" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com/"
          crossOrigin="true"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700;800&family=Jost:wght@300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Navbar />

      <FixedEnquiry />
      <FixedWhatsapp />

      <div className="container-fluid p-0 overflow-hidden">
        <div className="row ">
          <div className="col-12">
            <div class="card text-bg-dark rounded-0 ">
              <img
                src="https://www.eduthrive.org/wp-content/uploads/2023/07/desktop-wallpaper-study-abroad-abroad.jpg"
                class="card-img img-fluid "
                style={{ maxHeight: "23rem", mixBlendMode: "multiply" }}
                alt="admission_image"
              />
              <div class="card-img-overlay align-self-end">
                <div className="text-uppercase text-white text-center fs-2 fw-bold">
                  Recruting partner
                </div>
                <div className="d-flex flex-row align-items-center justify-content-center gap-3">
                  <div>
                    <Link
                      to=""
                      href="/"
                      className="text-decoration-none text-white fs-5"
                    >
                      Home
                    </Link>
                  </div>
                  <div className="text-white">
                    <FaArrowRight />
                  </div>

                  <div className="text-white fs-5"> Recruting Partner</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <div className="row">
          <div className="col-md-8">
            <div className="">
              <h5
                className="text-uppercase fw-bold h4"
                style={{ color: "#fe5722" }}
              >
                CONNECTING STUDENTS AND RECRUITERS TO GLOBAL EDUCATION
              </h5>
              <p className="lh-lg text-muted" style={{ textAlign: "justify" }}>
                At our core, we cultivate sustainable partnerships founded on
                flexibility and adaptability. With a keen understanding of your
                challenges and aspirations, we collaborate to engineer smarter
                solutions that mitigate the risks associated with
                internationalization. Our focus remains on enhancing your
                offerings, connecting you with top-tier students worldwide, and
                preparing them for prosperous careers. Simultaneously
              </p>
              <p className="lh-lg text-muted" style={{ textAlign: "justify" }}>
                Through our partnerships, students gain access to leading global
                institutions, assured of the support they need to become future
                global citizens and leaders. Meanwhile, our partners reap the
                benefits of motivated, well-prepared students, enriched campus
                life, and substantial additional revenues.
              </p>
              <p className="lh-lg text-muted" style={{ textAlign: "justify" }}>
                Join our network with united mission to reach, support, and
                educate students from every corner of the globe. Each
                partnership model is unique, offering global reach, invaluable
                insights, and an unwavering dedication to delivering
                unparalleled international student experiences within inspiring
                study environments..
              </p>
            </div>
          </div>
          <div className="col-md-4 ">
            <img
              src={partner2}
              alt="image"
              className="rounded-0  img-fluid border-0 "
            />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row g-3  ">
          <div className="col-lg-6 align-self-center">
            <div
              className="card card-body  bg-light  "
              style={{
                borderLeftColor: "#fe5722",
                borderLeftWidth: "9px",
                height: "10rem",
              }}
            >
              <div className="d-flex d-flex-row">
                <div className="icon">
                  {" "}
                  <span className="pe-2 fs-6" style={{ color: "#fe5722" }}>
                    <FaCircleCheck />
                  </span>{" "}
                </div>
                <div className="d-flex flex-column">
                  <h5 className="card-title fw-bold">Competitive Rates:</h5>
                  <p
                    className="card-text"
                    style={{ textAlign: "justify", fontSize: "15px" }}
                  >
                    We offer high rates on net tuition fees based on invoices
                    settled when students enroll.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 align-self-center">
            <div
              className="card card-body  bg-light   "
              style={{
                borderLeftColor: "#fe5722",
                borderLeftWidth: "9px",
                height: "10rem",
              }}
            >
              <div className="d-flex d-flex-row">
                <div className="icon">
                  {" "}
                  <span className="pe-2 fs-6" style={{ color: "#fe5722" }}>
                    <FaCircleCheck />
                  </span>{" "}
                </div>
                <div className="d-flex flex-column">
                  <h5 className="card-title fw-bold">
                    Comprehensive Services:
                  </h5>
                  <p
                    className="card-text"
                    style={{ textAlign: "justify", fontSize: "15px" }}
                  >
                    Access a wide range of services for your students, including
                    counseling, financial services, travel arrangements, housing
                    assistance, insurance, enrollment, visa support, and more,
                    all in one place.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 align-self-center">
            <div
              className="card card-body  bg-light  "
              style={{
                borderLeftColor: "#fe5722",
                borderLeftWidth: "9px",
                height: "10rem",
              }}
            >
              <div className="d-flex d-flex-row">
                <div className="icon">
                  {" "}
                  <span className="pe-2 fs-6" style={{ color: "#fe5722" }}>
                    <FaCircleCheck />
                  </span>{" "}
                </div>
                <div className="d-flex flex-column">
                  <h5 className="card-title fw-bold">Streamlined Process:</h5>
                  <p
                    className="card-text"
                    style={{ textAlign: "justify", fontSize: "15px" }}
                  >
                    Enjoy a simple and hassle-free online recruitment process
                    with an easy-to-use platform designed exclusively for you.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 align-self-center">
            <div
              className="card card-body  bg-light  "
              style={{
                borderLeftColor: "#fe5722",
                borderLeftWidth: "9px",
                height: "10rem",
              }}
            >
              <div className="d-flex d-flex-row">
                <div className="icon">
                  {" "}
                  <span className="pe-2 fs-6" style={{ color: "#fe5722" }}>
                    <FaCircleCheck />
                  </span>{" "}
                </div>
                <div className="d-flex flex-column">
                  <h5 className="card-title fw-bold">Dedicated Support:</h5>
                  <p
                    className="card-text"
                    style={{ textAlign: "justify", fontSize: "15px" }}
                  >
                    Receive personalized support from our team, ensuring
                    successful conversion of your student prospects.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 align-self-center">
            <div
              className="card card-body  bg-light  "
              style={{
                borderLeftColor: "#fe5722",
                borderLeftWidth: "9px",
                height: "10rem",
              }}
            >
              <div className="d-flex d-flex-row">
                <div className="icon">
                  {" "}
                  <span className="pe-2 fs-6" style={{ color: "#fe5722" }}>
                    <FaCircleCheck />
                  </span>{" "}
                </div>
                <div className="d-flex flex-column">
                  <h5 className="card-title fw-bold">
                    Exclusive Scholarships and Deals
                  </h5>
                  <p
                    className="card-text"
                    style={{ textAlign: "justify", fontSize: "15px" }}
                  >
                    Take advantage of up to 100% scholarships* for your
                    students, with offerings varying across different
                    universities.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 align-self-center">
            <div
              className="card card-body  bg-light  "
              style={{
                borderLeftColor: "#fe5722",
                borderLeftWidth: "9px",
                height: "10rem",
              }}
            >
              <div className="d-flex d-flex-row">
                <div className="icon">
                  {" "}
                  <span className="pe-2 fs-6" style={{ color: "#fe5722" }}>
                    <FaCircleCheck />
                  </span>{" "}
                </div>
                <div className="d-flex flex-column">
                  <h5 className="card-title fw-bold">Visa Support:</h5>
                  <p
                    className="card-text"
                    style={{ textAlign: "justify", fontSize: "15px" }}
                  >
                    Benefit from dedicated visa support for each country,
                    including assistance with the visa process and interview
                    preparation for your students, ensuring a smooth and
                    successful process from start to finish.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid my-3 partner5">
        <div className="row  ">
          <div className="col-md-7 p-3">
            <h4
              className="fw-uppercase fw-bold h3"
              style={{ color: "#fe5722" }}
            >
              COLLABORATE WITH EDUFYND FOR A PROMISING FUTURE AHEAD
            </h4>
            <p className="lh-lg text-white" style={{ textAlign: "justify" }}>
              Transform your business with EduFynd! As a leading overseas
              education service provider, we offer unmatched expertise and
              innovative solutions. Partner with us to expand your reach, cater
              to global students, and achieve remarkable growth. Choose EduFynd
              as your trusted business partner for a successful journey in the
              education sector.
            </p>
          </div>
          <div className="col-md-5 p-0">
            <img
              src={cta_4_shape2}
              alt=""
              className="img-fluid object-fit-cover"
            />
          </div>
        </div>
      </div>
      <div className="container-fluid my-4">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="">
                <h5
                  className="text-uppercase fw-bold"
                  style={{ color: "#fe5722" }}
                >
                  why edufynd?
                </h5>
                <h3 className="fw-bold h1">Your Success, Our Sole Mission</h3>
                <p
                  className="lh-lg text-muted"
                  style={{ textAlign: "justify" }}
                >
                  In the dynamic landscape of international student recruitment,
                  EduFynd stands out as a premier provider of tailored business
                  and technology solutions, catering to both aspiring and
                  established recruitment partners.
                </p>
                <p
                  className="lh-lg text-muted"
                  style={{ textAlign: "justify" }}
                >
                  EduFynd has earned a reputation for delivering exceptional
                  services that meet the diverse needs of our partners. Our
                  global presence spans two continents, allowing us to connect
                  students with educational opportunities across the globe.
                  Through strategic partnerships with over 300 universities
                  worldwide, we offer a comprehensive range of options to suit
                  every student's academic and career aspirations.
                </p>
                <p
                  className="lh-lg text-muted"
                  style={{ textAlign: "justify" }}
                >
                  EduFynd's tailored business and technology solutions are
                  designed to streamline the recruitment process and enhance the
                  overall experience for students and partners alike. Our
                  cutting-edge platform provides a seamless interface for
                  students to explore educational opportunities, while our
                  advanced analytics offer valuable insights for recruitment
                  partners to make informed decisions and drive
                  growth.Collaborate with us today to discover how our tailored
                  solutions can help you achieve your goals and drive success in
                  the international student recruitment sector.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row g-4 justify-content-center">
                <div className="col-md-6">
                  <div className="card shadow-lg border-0 rounded-2 h-100  text-center p-2">
                    <img
                      src="https://cdn.pixabay.com/photo/2019/09/13/15/32/graduation-4474213_1280.png"
                      alt=""
                      className="img-fluid rounded-pill mx-auto d-block mt-2  border border-2 border-primary p-2 border-bottom-0"
                      style={{ width: "100px", height: "100px" }}
                    />
                    <div className="card-body ">
                      <div className="card-title">
                        <h4 className="text-center fw-bold">Global Reach</h4>
                        <p
                          className="card-text  style={{textAlign:'justify',fontSize:'15px'}}lh-lg"
                          style={{ textAlign: "justify", fontSize: "15px" }}
                        >
                          Expand Your Student Benefits with Access to 300+
                          Universities Worldwide.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card  shadow-lg border-0 rounded-2 h-100  text-center  p-2">
                    <img
                      src="https://cdn.pixabay.com/photo/2019/09/13/15/32/graduation-4474213_1280.png"
                      alt=""
                      className="img-fluid rounded-pill mx-auto d-block mt-2 border border-2 border-primary p-2 border-bottom-0 "
                      style={{ width: "100px", height: "100px" }}
                    />
                    <div className="card-body ">
                      <div className="card-title">
                        <h4 className="text-center fw-bold">
                          Enhanced Learning
                        </h4>
                        <p
                          className="card-text  style={{textAlign:'justify',fontSize:'15px'}}lh-lg"
                          style={{ textAlign: "justify", fontSize: "15px" }}
                        >
                          Collaborate with us to enrich curriculam, share
                          pedagogical insights, and offer students a truly
                          global education.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="card shadow-lg border-0 rounded-2 h-100   text-center p-2">
                    <img
                      src="https://cdn.pixabay.com/photo/2019/09/13/15/32/graduation-4474213_1280.png"
                      alt=""
                      className="img-fluid rounded-pill mx-auto d-block mt-2 border border-2 border-primary p-2 border-bottom-0 "
                      style={{ width: "100px", height: "100px" }}
                    />
                    <div className="card-body ">
                      <div className="card-title">
                        <h4 className="text-center fw-bold">Mutual Growth</h4>
                        <p
                          className="card-text  style={{textAlign:'justify',fontSize:'15px'}}lh-lg"
                          style={{ textAlign: "justify", fontSize: "15px" }}
                        >
                          We enhance your institution's global recognition while
                          advancing our mission to empower students and
                          educators through exceptional educational
                          opportunities.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card  shadow-lg border-0 rounded-2 h-100  text-center  p-2">
                    <img
                      src="https://cdn.pixabay.com/photo/2019/09/13/15/32/graduation-4474213_1280.png"
                      alt=""
                      className="img-fluid rounded-pill mx-auto d-block mt-2  border border-2 border-primary p-2 border-bottom-0"
                      style={{ width: "100px", height: "100px" }}
                    />
                    <div className="card-body ">
                      <div className="card-title">
                        <h4 className="text-center fw-bold">
                          Diverse Collaborations
                        </h4>
                        <p
                          className="card-text  style={{textAlign:'justify',fontSize:'15px'}}lh-lg"
                          style={{ textAlign: "justify", fontSize: "15px" }}
                        >
                          Whether it's student exchange programs, joint research
                          endeavors, or academic collaborations, we are
                          dedicated to crafting an arrangement that aligns
                          perfectly with your vision.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid bg-gradient bg-primary my-4">
        <div className="row ">
          <div className="col-md-3 border-end py-5">
            <h3 className="fw-bold text-center text-white">300+</h3>
            <h5 className="text-captialize fw-semibold text-center text-white">
              {" "}
              Prestigious Universities
            </h5>
          </div>
          <div className="col-md-3 border-end py-5">
            <h3 className="fw-bold text-center text-white">8500+</h3>
            <h5 className="text-captialize fw-semibold text-center text-white">
              {" "}
              Over Course Options
            </h5>
          </div>
          <div className="col-md-3 border-end py-5">
            <h3 className="fw-bold text-center text-white">10+</h3>
            <h5 className="text-captialize fw-semibold text-center text-white">
              {" "}
              Years of Expertise
            </h5>
          </div>
          <div className="col-md-3  py-5">
            <h3 className="fw-bold text-center text-white">20+</h3>
            <h5 className="text-captialize fw-semibold text-center text-white">
              {" "}
              Countries{" "}
            </h5>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <div className="row g-5">
          <div className="col-md-6">
            <h4 className="text-uppercase fw-bold" style={{ color: "#fe5722" }}>
              BENEFIT WITH EDUFYND
            </h4>
            <h2 className="fw-bold">Benefits of associating with EduFynd</h2>
            <p className="lh-lg fw-semibold " style={{ textAlign: "justify" }}>
              <span style={{ color: "#fe5722" }}>
                {" "}
                <span className="pe-2">
                  {" "}
                  <FaCheckCircle />
                </span>{" "}
                Higher Commission:
              </span>{" "}
              Swift sharing of commission as soon as paid by universities. A
              proficient team for a quick response to your commission queries
            </p>
            <p className="lh-lg fw-semibold " style={{ textAlign: "justify" }}>
              <span style={{ color: "#fe5722" }}>
                {" "}
                <span className="pe-2">
                  {" "}
                  <FaCheckCircle />
                </span>{" "}
                Extensive Network:
              </span>{" "}
              Partnering with EduFynd grants you access to a vast network of
              educational institutions and potential students globally,
              expanding your reach and opportunities for collaboration.
            </p>
            <p className="lh-lg fw-semibold " style={{ textAlign: "justify" }}>
              <span style={{ color: "#fe5722" }}>
                {" "}
                <span className="pe-2">
                  {" "}
                  <FaCheckCircle />
                </span>{" "}
                Innovative Technology:
              </span>{" "}
              Leverage our cutting-edge technology platform, which facilitates
              seamless communication, efficient processes, and data-driven
              decision-making, enhancing productivity and effectiveness in your
              operations.
            </p>
            <p className="lh-lg fw-semibold " style={{ textAlign: "justify" }}>
              <span style={{ color: "#fe5722" }}>
                {" "}
                <span className="pe-2">
                  {" "}
                  <FaCheckCircle />
                </span>{" "}
                Ethical Practices:{" "}
              </span>{" "}
              EduFynd upholds the highest standards of ethics and integrity in
              all our dealings. Partnering with us ensures that you align with a
              reputable and trustworthy organization committed to fair and
              transparent practices.
            </p>
            <p className="lh-lg fw-semibold " style={{ textAlign: "justify" }}>
              <span style={{ color: "#fe5722" }}>
                {" "}
                <span className="pe-2">
                  {" "}
                  <FaCheckCircle />
                </span>{" "}
                Market Leadership:{" "}
              </span>{" "}
              With a proven track record of success and a strong presence in the
              overseas education sector, partnering with EduFynd can enhance
              your credibility and reputation, positioning you as a leader in
              your field.
            </p>
          </div>
          <div className="col-md-6 ">
            <div className="card b-white shadow border-0 h-100   p-2">
              <div className="card-body">
                <h4
                  className="card-title fw-bold h2 mb-4 px-2 text-capitalize"
                  style={{ color: "#fe5722" }}
                >
                  Let's shape the future of education together
                </h4>
                <TypesofEnquiry />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Recuriting;
