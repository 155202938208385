import React from "react";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import { FaArrowRight } from "react-icons/fa6";
import { FaCircleCheck } from "react-icons/fa6";
import { Helmet } from "react-helmet";
import FixedEnquiry from "../../Components/fixed compoents/FixedEnquiry";
import FixedWhatsapp from "../../Components/fixed compoents/FixedWhatsapp";
import { Link } from "react-router-dom";
export const Sop = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta http-equiv="x-ua-compatible" content="ie=edge" />
        <title> Overseas Education Service in Chennai to Study Abroad</title>
        <meta name="author" content="https://afynd.com/" />
        <meta
          name="description"
          content="We take care from Application to Acceptance. Our Overseas Education services include admission procedure, documentation, VISA assistance, Education Loan assistance from a reputed bank, pre/post departure service."
        />
        <meta
          name="keywords"
          content="Application to Acceptance, Overseas Education, admission procedure, VISA assistance, Education Loan assistance, pre/post departure service"
        />
        <meta name="robots" content="INDEX,FOLLOW" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,shrink-to-fit=no"
        />
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="assets/images/edufynd-fav-logo"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="assets/images/edufynd-fav-logo"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="assets/images/edufynd-fav-logo"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="assets/images/edufynd-fav-logo"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="assets/images/edufynd-fav-logo"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="assets/images/edufynd-fav-logo"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="assets/images/edufynd-fav-logo"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="assets/images/edufynd-fav-logo"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="assets/images/edufynd-fav-logo"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="assets/images/edufynd-fav-logo"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="assets/images/edufynd-fav-logo"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="assets/images/edufynd-fav-logo"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="assets/images/edufynd-fav-logo"
        />
        <link rel="manifest" href="assets/img/favicons/manifest.php" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta
          name="msapplication-TileImage"
          content="assets/img/favicons/ms-icon-144x144.png"
        />
        <meta name="theme-color" content="#ffffff" />
        <link rel="preconnect" href="https://fonts.googleapis.com/" />
        <link rel="preconnect" href="https://fonts.gstatic.com/" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700;800&amp;family=Jost:wght@300;400;500;600;700;800;900&amp;family=Roboto:wght@100;300;400;500;700&amp;display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Navbar />
      <FixedEnquiry />
      <FixedWhatsapp />
      <div className="container-fluid p-0 overflow-hidden">
        <div className="row ">
          <div className="col-12">
            <div class="card text-bg-dark rounded-0 ">
              <img
                src="https://www.eduthrive.org/wp-content/uploads/2023/07/desktop-wallpaper-study-abroad-abroad.jpg"
                class="card-img img-fluid "
                style={{ maxHeight: "23rem", mixBlendMode: "multiply" }}
                alt="admission_image"
              />
              <div class="card-img-overlay align-self-end">
                <div className="text-uppercase text-white text-center fs-2 fw-bold">
                  Sop Crafting
                </div>
                <div className="d-flex flex-row align-items-center justify-content-center gap-3">
                  <div>
                    <Link
                      to=""
                      href="/"
                      className="text-decoration-none text-white fs-5"
                    >
                      Home
                    </Link>
                  </div>
                  <div className="text-white">
                    <FaArrowRight />
                  </div>
                  <div>
                    <Link
                      to=""
                      href="/Service"
                      className="text-decoration-none text-white fs-5"
                    >
                      Services
                    </Link>
                  </div>
                  <div className="text-white">
                    <FaArrowRight />
                  </div>
                  <div className="text-white fs-5"> SOP Crafting</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <div className="row">
          <h5
            className="text-start text-uppercase fw-semibold "
            style={{ color: "#fe5722" }}
          >
            {" "}
            OUR SERVICES
          </h5>
          <h1 className="text-start fw-bold">Sop Crafting</h1>
          <div className="col-md-7">
            <p className="text-muted py-2" style={{ textAlign: "justify" }}>
              At EduFynd, we recognize the significance of your Statement of
              Purpose (SOP) in conveying your unique story to educational
              institutions. Our expert team collaborates closely with you to
              create a personalized narrative that highlights your ambitions,
              experiences, and objectives in a compelling manner.By entrusting
              us with your SOP, you're not just receiving a document; you're
              gaining a tailored narrative that reflects your individuality,
              aspirations, and readiness for academic success. Your story
              matters, and we're here to help you tell it in the most impactful
              way possible.
            </p>
            <h4 className="text-start fw-bold py-1">
              Crafting Your Tailored Narrative
            </h4>
            <div className="d-flex flex-row align-items-start justify-content-between">
              <div className="d-flex flex-column ">
                <p
                  class=" fw-semibold"
                  style={{ color: "#0f2239", textAlign: "justify" }}
                >
                  {" "}
                  <span className="pe-2" style={{ color: "#fe5722" }}>
                    <FaCircleCheck />
                  </span>{" "}
                  Expert Guidance{" "}
                </p>
                <p
                  class=" fw-semibold"
                  style={{ color: "#0f2239", textAlign: "justify" }}
                >
                  {" "}
                  <span className="pe-2" style={{ color: "#fe5722" }}>
                    <FaCircleCheck />
                  </span>
                  Close Collaboration
                </p>
                <p
                  class=" fw-semibold"
                  style={{ color: "#0f2239", textAlign: "justify" }}
                >
                  {" "}
                  <span className="pe-2" style={{ color: "#fe5722" }}>
                    <FaCircleCheck />
                  </span>{" "}
                  Emphasis on Ambitions{" "}
                </p>
              </div>
              <div className="d-flex flex-column ">
                <p
                  class=" fw-semibold"
                  style={{ color: "#0f2239", textAlign: "justify" }}
                >
                  {" "}
                  <span className="pe-2" style={{ color: "#fe5722" }}>
                    <FaCircleCheck />
                  </span>{" "}
                  Highlighting Experiences{" "}
                </p>
                <p
                  class=" fw-semibold"
                  style={{ color: "#0f2239", textAlign: "justify" }}
                >
                  {" "}
                  <span className="pe-2" style={{ color: "#fe5722" }}>
                    <FaCircleCheck />
                  </span>
                  Clarity of Objectives
                </p>
              </div>
              <div className="d-flex flex-column "></div>
            </div>
          </div>
          <div className="col-md-5">
            <div className="h-100 text-center">
              <img
                src="https://st2.depositphotos.com/39091214/48280/i/450/depositphotos_482803198-stock-photo-word-admission-written-wooden-cubes.jpg"
                alt=""
                className="img-fluid  h-100 mx-auto d-block "
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <div className="row g-5 justify-content-center">
          <div className="col-md-4">
            <div className=" card h-100 position-relative  shadow-sm  p-3">
              <div className="card-body ">
                <h5
                  className="card-title text-center fw-bold"
                  style={{ color: "#0f2239" }}
                >
                  Expert Guidance
                </h5>
                <p
                  className="card-text"
                  style={{ color: "#0f2239", textAlign: "justify" }}
                >
                  Our seasoned professionals provide expert guidance throughout
                  the SOP creation process, ensuring every aspect is
                  meticulously addressed.
                </p>
                <div className="position-absolute position-absolute bottom-0 start-50 translate-middle-x py-2  ">
                  <Link
                    to=""
                    className="btn btn-sm text-uppercase fw-semibold px-4 py-2 text-white"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal2"
                    style={{
                      backgroundColor: "#fe5722",
                      color: "#fff",
                      fontSize: "12px",
                    }}
                  >
                    Enquire Now
                  </Link>
                </div>
                <div
                  class="modal fade"
                  id="exampleModal2"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-dialog-scrollable">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h1 class="modal-title fs-5" id="exampleModalLabel">
                          Enquriy Form
                        </h1>
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div class="modal-body">
                        <form
                          action=""
                          className="p-2 needs-validation"
                          novalidate
                          style={{ fontSize: "16px" }}
                        >
                          <div className="row g-3 mb-3">
                            <div className="col">
                              <div className="form-floating">
                                <input
                                  type="text"
                                  className="form-control "
                                  placeholder="Name"
                                  id="floatingInputGrid"
                                />
                                <label for="floatingInputGrid">Name</label>
                              </div>
                            </div>
                            <div className="col">
                              <div className="form-floating">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Country"
                                  id="floatingInputGrid"
                                />
                                <label for="floatingInputGrid">Country</label>
                              </div>
                            </div>
                          </div>
                          <div className="row g-3 mb-3">
                            <div className="col">
                              <div className="form-floating">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="University"
                                  id="floatingInputGrid"
                                  required
                                />
                                <label for="floatingInputGrid">
                                  University
                                </label>
                              </div>
                            </div>
                            <div className="col">
                              <div className="form-floating">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Student ID"
                                  id="floatingInputGrid"
                                />
                                <label for="floatingInputGrid">
                                  Student ID
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="row g-3 mb-3">
                            <div className="col">
                              <div className="form-floating">
                                <input
                                  type="email"
                                  className="form-control"
                                  placeholder="E-Mail"
                                  id="floatingInputGrid"
                                />
                                <label for="floatingInputGrid">E-Mail</label>
                              </div>
                            </div>
                            <div className="col">
                              <div className="form-floating">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Contact"
                                  id="floatingInputGrid"
                                />
                                <label for="floatingInputGrid">Contact</label>
                              </div>
                            </div>
                          </div>
                          <div class="form-floating">
                            <textarea
                              class="form-control"
                              placeholder="Message"
                              id="floatingTextarea"
                              style={{ height: "110px" }}
                            ></textarea>
                            <label for="floatingTextarea">Message</label>
                          </div>
                        </form>
                      </div>
                      <div class="modal-footer">
                        <button
                          type="button"
                          class="btn  fw-semibold fw-semibold text-uppercase px-4 py-2 text-white"
                          data-bs-dismiss="modal"
                          style={{
                            backgroundColor: "#231f20",
                            color: "#fff",
                            fontSize: "12px",
                          }}
                        >
                          Close
                        </button>
                        <button
                          type="button"
                          class="btn fw-semibold text-uppercase px-4 py-2 text-white "
                          style={{
                            backgroundColor: "#fe5722",
                            color: "#fff",
                            fontSize: "12px",
                          }}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className=" card h-100 position-relative  shadow-sm  p-3">
              <div className="card-body ">
                <h5
                  className="card-title text-center fw-bold"
                  style={{ color: "#0f2239" }}
                >
                  Close Collaboration
                </h5>
                <p
                  className="card-text"
                  style={{ color: "#0f2239", textAlign: "justify" }}
                >
                  We value your input. Through close collaboration, we work
                  together to understand your aspirations, experiences, and
                  goals, infusing your voice into the narrative.
                </p>
                <div className="position-absolute position-absolute bottom-0 start-50 translate-middle-x py-2  ">
                  <Link
                    to=""
                    className="btn btn-sm text-uppercase fw-semibold px-4 py-2 text-white"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal2"
                    style={{
                      backgroundColor: "#fe5722",
                      color: "#fff",
                      fontSize: "12px",
                    }}
                  >
                    Enquire Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className=" card h-100 position-relative  shadow-sm  p-3">
              <div className="card-body ">
                <h5
                  className="card-title text-center fw-bold"
                  style={{ color: "#0f2239" }}
                >
                  Emphasis on Ambitions
                </h5>
                <p
                  className="card-text"
                  style={{ color: "#0f2239", textAlign: "justify" }}
                >
                  Your ambitions are at the core of the SOP. We strategically
                  emphasize your academic and career goals to align with the
                  institution's expectations.
                </p>
                <div className="position-absolute position-absolute bottom-0 start-50 translate-middle-x py-2  ">
                  <Link
                    to=""
                    className="btn btn-sm text-uppercase fw-semibold px-4 py-2 text-white"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal2"
                    style={{
                      backgroundColor: "#fe5722",
                      color: "#fff",
                      fontSize: "12px",
                    }}
                  >
                    Enquire Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className=" card h-100 position-relative  shadow-sm  p-3">
              <div className="card-body ">
                <h5
                  className="card-title text-center fw-bold"
                  style={{ color: "#0f2239" }}
                >
                  Highlighting Experiences
                </h5>
                <p
                  className="card-text"
                  style={{ color: "#0f2239", textAlign: "justify" }}
                >
                  Your unique experiences are woven into the narrative,
                  showcasing how they have shaped your academic journey and
                  contributed to your readiness for the chosen program.
                </p>
                <div className="position-absolute position-absolute bottom-0 start-50 translate-middle-x py-2  ">
                  <Link
                    to=""
                    className="btn btn-sm text-uppercase fw-semibold px-4 py-2 text-white"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal2"
                    style={{
                      backgroundColor: "#fe5722",
                      color: "#fff",
                      fontSize: "12px",
                    }}
                  >
                    Enquire Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className=" card h-100 position-relative  shadow-sm  p-3">
              <div className="card-body ">
                <h5
                  className="card-title text-center fw-bold"
                  style={{ color: "#0f2239" }}
                >
                  Clarity of Objectives
                </h5>
                <p
                  className="card-text"
                  style={{ color: "#0f2239", textAlign: "justify" }}
                >
                  We ensure that your objectives are clear and align with the
                  specific program and institution, presenting a strong case for
                  your candidacy.
                </p>
                <div className="position-absolute position-absolute bottom-0 start-50 translate-middle-x py-2  ">
                  <Link
                    to=""
                    className="btn btn-sm text-uppercase fw-semibold px-4 py-2 text-white"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    style={{
                      backgroundColor: "#fe5722",
                      color: "#fff",
                      fontSize: "12px",
                    }}
                  >
                    Enquire Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Sop;
